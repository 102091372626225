import { Deserializable } from "./deserializable.model";
import { Business } from './business.model';

export class Client {
	id:number;
	first_name:string;
	last_name:string;
	nickname:string;
	email:string;
	phone:string;
	birthday:string;
	sex:boolean;
	document_type:string;
	document_number:number;
	licence_plate:string;
	confirmed_orders:number;
	sign_in_count:number;
	businesses:Business[] = [];
	today_used_discounts:number = 0;
	is_guest:boolean = true;
	
	isOlderThan(age:number){
		if (this.birthday) {
			var current_age = this.calculateAge(new Date(this.birthday));
			return current_age >= age;
		}

		return false;
	}

	deserialize(input: any) {
	    Object.assign(this, input);

	    if (input.businesses != undefined) {
	    	this.businesses = input.businesses.map ((b) => new Business().deserialize(b));
	    }
	    return this;
	}

	firstTime():boolean{
		return this.sign_in_count == null || this.sign_in_count == 1;
	}

	firstOrder():boolean{

		return (this.confirmed_orders == 0) ;// || (this.confirmed_orders == 1);

	}

	hasBusinesses():boolean{
		return this.businesses.length > 0;
	}

	getBusiness():Business{
		return this.businesses[0];
	}

	

	private calculateAge(birthday) { // birthday is a date
	    var ageDifMs = Date.now() - birthday.getTime();
	    var ageDate = new Date(ageDifMs); // miliseconds from epoch
	    return Math.abs(ageDate.getUTCFullYear() - 1970);
	  }
}
