import { Deserializable } from "./deserializable.model";

export class Device implements Deserializable {
    name: string;
    status_id: string;

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	}
}
