import { OrderDetail } from './order-detail.model';
import { Product } from './product.model';
import { Client } from './client.model';
import { Store } from './store.model';
import { StoreRange } from './store-range.model';
import { Address } from './address.model';
import { Device } from './device.model';
import { Table } from './table.model';
import { Discount } from './discount.model';
import { OrderInfoGift } from './order-info-gift.model';

export class Order {
	order_details: OrderDetail[] = [];
	amount: number = 0;
	to_go: boolean;
	scheduled: string;
	client:Client;
	id:string;
	hash_id:string;
	status_id:string;
	store_id:number;
	store:Store;
	deliver_type:string;
	box:any;
	store_deliver_type_range:StoreRange;
	delivery_address:Address;
	address:Address;
	drafted_at:number;
	created_at:string;
	updated_at:string;
	number:string;
	actual_device:Device;
	payment_method:string;
	minimum_amount:number = 0;
	order_fees:any[] = [];
	beacon_id:number;
	deliver_method:string;
	unlock_type:string;
	unlock_code:string;
	paid:boolean = false;
	table:Table;
	store_deliver_type_range_id:any;
	discounts:Discount[] = [];
	discount:number = 0;
	validation_qr:string;
	business_id:number;
	business:string;
	observations:string;
	order_info_gift:OrderInfoGift;

	getMinDate() : any{
		var min = new Date();
		this.order_details.forEach((od) => {
			if (od.product.from_date > min) {
				min = od.product.from_date;
			}
		});

		return min;
	}

	getMaxTimeOffset() : number {
		var max = 0;
		this.order_details.forEach((od) => {
			if (od.product.time_offset > max) {
				max = od.product.time_offset;
			}
		});

		return max;
	}

	getShowableDiscounts(){
		return this.discounts.filter((d) => !d.apply_to_all);
	}

	addDiscount(discount:Discount){
		var index = this.discounts.findIndex((d) => d.code == discount.code);

		if (index<0) { //&& this.discounts.length == 0)
			if(this.client){
				if(!this.client.is_guest){
					this.discounts.push(discount);
				}

				if(this.client.is_guest && !discount.for_registered_user){
					this.discounts.push(discount);
				}
			}
		}
	}

	removeDiscount(discount:Discount){
		var i = this.discounts.map(e => e.code).indexOf(discount.code)

		if ( i > -1 )
			this.discounts.splice( i, 1 )

		this.order_details.forEach(
			order_detail => order_detail.removeDiscount(discount)
		)
	}

	addDiscounts(discounts:Discount[]){
		discounts.forEach((d) => this.addDiscount(d));
	}

	getDiscounts(){
		return this.discounts;
	}

	public getOrderDetailsPerProductTypeId(product_type_id){
		return this.order_details.filter( od => od.product.product_type_id == product_type_id);
	}

	public getCountOrderDetailsPerProductTypeId(product_type_id){
		var aux = this.getOrderDetailsPerProductTypeId(product_type_id);
		return aux.map(od => od.quantity).reduce((a,b) => a+b,0);
	}

	public addProduct(product:Product, quantity:number):boolean {
		// Check por máx
		if (product.product_type_id && product.max_products_per_category && product.max_products_per_category > 0) {
			var count_items = this.getCountOrderDetailsPerProductTypeId(product.product_type_id);

			if ((count_items + quantity) > product.max_products_per_category) {
				return false;
			}
		}

		//check if exists
		let i = this.order_details.findIndex( order => order.product.getCode() == product.getCode());

		if (i>=0) {
			this.order_details[i].quantity+=quantity;
		}else{
			this.order_details.push(new OrderDetail(product,quantity));
		}

		this.updateAmount();

		return true;
	}

	public removeProduct(product:Product, quantity:number):void {
		//check if exists
		let i = this.order_details.findIndex( order => order.product.getCode() == product.getCode());

		// if not found then check if has additionals
		if (i<0) {
			if(product.hasAditionals()){
				i = this.order_details.findIndex( order => order.product.id == product.id);
			}
		}

		if(i>=0){
			this.order_details[i].quantity-=quantity;

			if (this.order_details[i].quantity <= 0) {
				this.order_details.splice(i,1);
			}
		}

		this.updateAmount();
	}

	public emptyChart():void{
	  this.order_details = [];
	  this.discounts.forEach(discount => {
		if (!discount.canApplyToOrder(this))
			this.removeDiscount(discount);
	  });
	}

	public getQuantityOfProduct(product:Product){
		let i = this.order_details.findIndex( order => order.product.getCode() == product.getCode());

		if (i>=0) {
			return this.order_details[i].quantity;
		}

		return 0;
	}

	hasDeliverCost():boolean{
		if (this.hasDeliverMethod()) {
			return this.isDelivery() && this.delivery_address && this.delivery_address.deliver_cost > 0;
		}

		return false;
	}

	getDeliverCost():number{
		if (this.delivery_address && this.hasDeliverCost()) {
			return this.delivery_address.deliver_cost;
		}

		return 0;
	}

	getMinimumAmount():number{
		if (this.hasDeliverMethod()) {
			if (this.isDelivery() && this.delivery_address ) {
				return this.delivery_address.minimum_amount;
			}else if(this.store){
				// TODO: FIX REVISAR
				let aux = this.deliver_method.replace("for_","");
				let current_i = this.store.deliver_methods.findIndex((dm) => dm.code == aux);

				if(current_i >= 0){
					let current_sdm = this.store.deliver_methods[current_i];

					return Math.max(current_sdm.minimum_amount, this.store.minimum_amount);
				}

				return this.store.minimum_amount;
			}else{
				return 0;
			}
		}
		
		return 0;
	}

	getTotalMinimumAmount():number{
		return this.getMinimumAmount() + this.getDeliverCost();
	}

	canPay():boolean{
		return this.getAmount() >= this.getMinimumAmount();
	}

	updateAmount(): void{
		this.amount = this.order_details.map((order_detail) => order_detail.amount * order_detail.quantity).reduce((a,b) => a+b,0);
	}

	getQuantity() : number {
		return this.order_details.map((order_detail) => order_detail.quantity).reduce((a,b) => a+b,0)
	}

	getProductQuantity(product:Product) : number {
		return this.order_details.map((order_detail) => order_detail.product.id == product.id ? order_detail.quantity : 0).reduce((a,b) => a+b,0)
	}

	getProductVariantsQuantity(product:Product) : number {
		return this.order_details.map((order_detail) => order_detail.product.id == product.id ? 1 : 0).reduce((a,b) => a+b,0)
	}

	getAmount() : number {
		return this.amount;
	}

	getDiscount() : number {
		return this.discount;
	}

	getTotal() : number {
		return this.getSubTotal() - this.getAppliedDiscount();
	}

	getSubTotal() : number{
		return this.getDeliverCost() + this.getAmount();
	}

	getAppliedDiscount() : number{
		let discounts = this.discounts.map( (discount) => discount.applyToOrder(this) ).reduce((a,b) => a+b,0);

		if(discounts > this.getSubTotal()){
			return this.getSubTotal();
		}
		
		return discounts;
	}

	confirm() : void {
	}

	getDescription() : string {
		return this.order_details.map ( (od) => od.quantity > 1 ? od.quantity + " " + od.product.name : od.product.name ).join("; ");
	}

	getStatus() : string {

		switch (this.status_id){
			case 'canceled':
				return 'Cancelado';

			case 'created':
			case 'payment_required':
				return 'Pendiente de Pago';

			case 'pending':
				return 'Pedido confirmado';

			case 'production':
				return 'En preparación';

			case 'dispatch':
				return 'En preparación';

			case 'ready':
				if (this.isDelivery())
					return 'En preparación';

				return 'Listo para retirar';

			case 'done':
				return 'Entregado!';

			case 'shipment':
				return 'En camino!';
			case 'paid':
				return 'Pago';

			default:
				return this.status_id;
		}
	}

	deserialize(input: any) {
	    Object.assign(this, input);

	    if (input) {
		    if (input.order_details != undefined) {
		    	//remap aditionals
		    	for (var i=0; i<input.order_details.length; i++){
		    		if (input.order_details[i].order_detail_aditionals != undefined) {
		    			var ad = input.order_details[i].order_detail_aditionals.map((add) => add.aditional);
		    			input.order_details[i].product.aditionals = ad;
		    		}
		    	}

		    	this.order_details = input.order_details.map( (od) => new OrderDetail(new Product().deserialize(od.product), od.quantity, od.amount, od.discount) );	
		    }else{
		    	this.order_details = [];
		    }

		    if (input.store_deliver_type_range != undefined) {
		    	this.store_deliver_type_range = new StoreRange().deserialize(input.store_deliver_type_range);
		    }

		    if (input.address != undefined) {
		    	this.address = new Address().deserialize(input.address);
		    }

		    if (input.delivery_address != undefined) {
		    	this.delivery_address = new Address().deserialize(input.delivery_address);
		    }

		    if (input.client != undefined) {
		    	this.client = new Client().deserialize(input.client);
		    }

		    if (input.actual_device != undefined) {
		    	this.actual_device = new Device().deserialize(input.actual_device);
		    }

		    if (input.store != undefined) {
		    	this.store = new Store().deserialize(input.store);
		    }

		    if (input.table != undefined) {
		    	this.table = new Table().deserialize(input.table);
		    }

		    if (input.discounts) {
		    	this.discounts = input.discounts.map((d) => new Discount().deserialize(d));
		    }

		    if (this.delivery_address) {
		    	this.minimum_amount = this.delivery_address.minimum_amount;
		    }
	    }


	    return this;
	}

	canGoToBox() : boolean {
		return this.status_id == 'ready' && this.box;
	}

	canReDoOrder() : boolean {
		return false;
		// return this.status_id == 'done';
	}

	isReady() : boolean {
		return this.status_id == 'ready';
	}

	isShipment() : boolean {
		return this.status_id == 'shipment';
	}

	isCanceled() : boolean {
		return this.status_id == 'canceled';	
	}

	isDone() : boolean {
		return this.status_id == 'done';	
	}

	hasDeliverMethod():boolean {
		if (this.deliver_type != null) {
			if (this.isToGo() || this.isInStore()) {
				return this.store != undefined;
			}

			if (this.isDelivery()) {
				return this.delivery_address != undefined;
			}
		}
		return false;
	}

	hasPaymentMethod():boolean {
		return this.payment_method != null;
	}

	getPaymentMethod():string {
		return this.payment_method;
	}

	hasStore():boolean{
		return this.store != null;
	}

	isDelivery() : boolean {
		return this.deliver_type == 'delivery';
	}

	isToGo() : boolean {
		if (this.deliver_type == 'to_go') {
			return true;
		}

		if (this.table && this.table.isFuelPump()) {
			return true;
		}
	}

	isInStore() : boolean {
		return this.deliver_type == 'in_store';
	}

	isPaid() : boolean {
		return this.paid;
	}

	isCashPayment():boolean {
		return this.payment_method == 'cash';
	}

	isCardPayment():boolean {

		let optionsCardPayment = ['payment_card', 'mercado_pago', 'modo_pago']

		return optionsCardPayment.includes(this.payment_method);

	}

	isForBox() : boolean {
		return this.deliver_method == 'box';
	}

	isForTable() : boolean {
		return this.deliver_method == 'for_table' && this.table && this.table.isTable();
	}

	isForFuelPump() : boolean {
		return this.deliver_method == 'for_table' && this.table && this.table.isFuelPump();
	}

	isForCounter() : boolean {
		return this.deliver_method == 'counter' && this.deliver_type == "in_store";	
	}

	isForTakeAway() : boolean{
		return this.deliver_method == 'counter' && this.deliver_type == "to_go";
	}

	isTakeAway() : boolean {
		return this.deliver_method == 'counter' && this.store_deliver_type_range_id;
	}

	unlockWithApp() : boolean {
		return this.unlock_type == 'app';
	}

	hasBeacon() : boolean {
		return this.deliver_method == 'beacon';	
	}

	boxIsReady() : boolean{
		return this.isForBox() && this.actual_device && this.actual_device.status_id == 'ready';
	}

	hasDevice() : boolean{
		return this.actual_device != undefined && this.actual_device != null;
	}

	isScheduled():boolean{
		return this.scheduled != undefined;
	}

	getCreatedDate(): Date {
		return new Date(this.created_at);
	}

	getUpdatedAt(): Date {
		return new Date(this.updated_at);	
	}

	getScheduled(): Date {
		return new Date(this.scheduled);	
	}

	getScheduledString():string {
		let scheduled = this.getScheduled();
		var schDay = scheduled.getDate();
		var schMonth = scheduled.getMonth() + 1;
		var schYear = scheduled.getFullYear();

		return schDay + '/' + schMonth + '/' + schYear;
	}



	isFuture(): boolean{
		return this.store_deliver_type_range_id != null;
	}

	getUntilTime(): String {
		var time = new Date(this.updated_at);
	    time.setMinutes(time.getMinutes() + 15);

	    var hours = (time.getHours() < 10 ? '0' : '') + time.getHours();
		var minutes = (time.getMinutes() < 10 ? '0' : '') + time.getMinutes();

		return hours + ":" + minutes + "hs";
	}

	isForNow():boolean{
		return this.store_deliver_type_range == undefined;
	}

	isForToday():boolean{
		var ret = false;
		if (this.isForNow()) {
			ret = true;
		} else {
			let scheduled = this.getScheduled();
			let today = new Date();
			var schDay = scheduled.getDate();
			var schMonth = scheduled.getMonth();
			var schYear = scheduled.getFullYear();
			if (schDay == today.getDate() && schMonth == today.getMonth() && schYear == today.getFullYear()) {
				ret = true;
			}
		}
		return ret;
	}

	getForDateString():string{
		if (this.store_deliver_type_range) {
			return this.store_deliver_type_range.dayToString() + " " +
				   this.store_deliver_type_range.dayOfMonth().getDate() + "/" +
				   (this.store_deliver_type_range.dayOfMonth().getMonth()+1) + " de " +
				   this.store_deliver_type_range.hoursToString();
		}

		if (this.scheduled) {
			return "SCHEDULED PARSE";
		}

		return "Ahora";
	}
}
