import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { MenuController } from '@ionic/angular'; 
import { NavController } from '@ionic/angular';

import { LocalSessionService } from '../services/local-session.service';

import { version } from '../../package.json';

import { environment } from '../environments/environment';

import { CoreService } from 'src/services/core.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public version:string = version;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    // {
    //   title: 'Mi Cuenta',
    //   url: '/mi-cuenta',
    //   icon: 'account_circle'
    // }
    // {
    //   title: 'DNI',
    //   url: '/dni',
    // },
    // {
    //   title: 'Ingresar',
    //   url: '/ingresar',
    // },
    // {
    //   title: 'Mis Pedidos',
    //   url: '/mis-pedidos',
    //   icon: 'restore'
    // },
    // {
    //   title: 'Login',
    //   url: '/login',
    //   icon: 'restore'
    // },
    // {
    //   title: 'Legales',
    //   url: '/legales',
    //   icon: 'article'
    // },
    // {
    //   title: 'Cerrar Sesión',
    //   url: '/logout',
    //   icon: 'exit_to_app'
    // }
  ];
  latitude: any;
  longitude: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private coreService: CoreService,
    public menuCtrl: MenuController,
    protected localSession: LocalSessionService,
    protected navCtrl: NavController,
  ) {

    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {

    this.coreService.setAuthToken(this.localSession.getAuthToken());

    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    if(environment.stores.force_select){
      this.appPages.push({
        title: 'Estaciones',
        url: '/select-store',
        icon: 'store'
      });
    }

    if (!this.localSession.getClient().is_guest) {
      this.appPages = this.appPages.concat([{
          title: 'Mi Cuenta',
          url: '/mi-cuenta',
          icon: 'account_circle'
        },
        {
          title: 'Mis Pedidos',
          url: '/mis-pedidos',
          icon: 'restore'
        },
      ]);

      if(environment.enable_my_coupons){
        this.appPages.push({
          title: 'Mis Cupones',
          url: '/mis-cupones',
          icon: 'sell'
        });
      }
    }

    if (this.localSession.isLoggedIn()) {
      this.appPages.push({
        title: 'Salir',
        url: '/logout',
        icon: 'exit_to_app'
      });
    }else{
      this.appPages.push({
        title: 'Ingresar',
        url: '/login/do',
        icon: 'exit_to_app'
      });
    }
  }
  
  toggleMenu() {
    this.menuCtrl.toggle(); //Add this method to your button click function
  }

  goToAbout(){
    this.navCtrl.navigateRoot("/about");
    this.menuCtrl.toggle();
  }
}
