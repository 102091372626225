import { Deserializable } from "./deserializable.model";
import { Discount } from './discount.model';

export class Business implements Deserializable {
	id:number;
	name: string;
	description: string;
	image: string;
	discounts: Discount[];

	deserialize(input: any) {
	    Object.assign(this, input);

	    if (input.discounts != undefined) {
	    	this.discounts = input.discounts.map ((d) => new Discount().deserialize(d));
	    }
	    
	    return this;
	}

	hasDiscounts():boolean{
		return this.discounts.length > 0;
	}

	getDiscount():Discount{
		return this.discounts[0];
	}

	getDiscounts():Discount[]{
		return this.discounts;
	}

	getImage():string{
		return this.image;
	}
}
