import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class ProductFilterPipe implements PipeTransform {

  transform(array: any[], product: string, info:string, searchCount:any) :any[] {
  	
  	if(product === ''){
  		searchCount.count = array.length;
  		return array;
  	}

  	product = product.toLowerCase();

    var data =  array.filter(item => {
    	return item[info].toLowerCase().includes(product);
    });

    searchCount.count = data.length;

    return data;
  }

}