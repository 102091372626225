import { Deserializable } from "./deserializable.model";

export class Address implements Deserializable {
	alias: string;
    street: string;
    number: number;
    floor: string;
    city: string;
    province: string;
    locality: string;
    postal_code:string;
    phone: string;
    is_new:boolean = false;
    lat:string;
    lng:string;
    cash_enabled:boolean;
    aux_province:any;
    aux_locality:any;
    minimum_amount:number = 0;
    deliver_cost:number = 0;
    confirmed:boolean = false;
    observations:string;

	deserialize(input: any) {
	    Object.assign(this, input);

        if (input.province != undefined) {
            this.province = input.province.id;
            this.aux_province = input.province;
            this.minimum_amount = input.province.minimum_amount;
            this.deliver_cost = input.province.deliver_cost;
        }

        if (input.aux_province != undefined) {
            if (input.aux_province.id) {
                this.province = input.aux_province.id;
            }

            this.minimum_amount = input.aux_province.minimum_amount;
            this.deliver_cost = input.aux_province.deliver_cost;
        }

        if (input.locality != undefined) {
            this.locality = input.locality.id;
            this.aux_locality = input.locality;
            if (input.locality.minimum_amount > 0) {
                this.minimum_amount = input.locality.minimum_amount;
            }

            if (input.locality.deliver_cost > 0) {
                this.deliver_cost = input.locality.deliver_cost;
            }
        }
        
        if (input.aux_locality != undefined) {
            if (input.aux_locality.id) {
                this.locality = input.aux_locality.id;
            }

            if (input.aux_locality.minimum_amount > 0) {
                this.minimum_amount = input.aux_locality.minimum_amount;
            }

            if (input.aux_locality.deliver_cost > 0) {
                this.deliver_cost = input.aux_locality.deliver_cost;
            }
        }

	    return this;
	}

    isValidAddress():boolean{
        return this.street != null && this.street.length > 0 && this.number != null && this.number > 0;
    }

    getFullAddress():string{
        if (this.aux_locality) {
            return this.street + " " + this.number + ", " + this.aux_locality.name;
        }

        return this.getSimpleAddress();
    }

    getSimpleAddress():string{
        return this.street + " " + this.number;
    }

    hasLatLng():boolean{
        return this.lat != undefined && this.lng != undefined;
    }
}
