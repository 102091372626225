import { Injectable } from '@angular/core';

//models
import { Product } from '../models/product.model';
import { ProductType } from '../models/product-type.model';
import { Order } from '../models/order.model';
import { Address } from '../models/address.model';
import { Client } from '../models/client.model';

import { Store } from '../models/store.model';
import { Discount } from '../models/discount.model';

import { OrderInfoGift } from '../models/order-info-gift.model';

import { RemoteOrder } from '../models/remote-order.model';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalSessionService {

  value:number = 1;
  order:Order;
  loggedIn:boolean = false;
  authToken:string = '';
  itemAdded:number = 0;
  client:Client;

  remoteOrder:any;

  stores:Store[] = [];

  currentStore:Store;

  selectedStoreId:number;

  idSelectProductType:number = null;

  defaultStoreCode:string;
  defaultTable:string;

  impulseBuyViewed:boolean = false;

  showLoginInModalVal:boolean = true;

  hasValidateAge:any = false;
  productTypes:ProductType[] = [];

  catalog:string;

  orderInfoGift:OrderInfoGift = null;

  backToLogin:boolean = false;

  refreshProductCatalog:boolean = true;

  public forceRefreshProductCatalog():boolean{
    return this.refreshProductCatalog;
  }

  public setForceRefreshProductCatalog(refresh:boolean = false):void{
    this.refreshProductCatalog = refresh;
  }

  public getAgeValidation(){
    return this.hasValidateAge;
  }

  public setAgeValidation(value:any){
    this.hasValidateAge = value;
  }

  constructor() { 
    var data = this.restore();
  	if (this.order == undefined) {
      //check in localstorage
      this.order = data.order;
      this.client = data.client;
      this.idSelectProductType = data.idSelectProductType

      //check age for validation
      if (environment.age_validation && environment.age_validation.min_years) {
        if (this.client.isOlderThan(environment.age_validation.min_years)){
          this.setAgeValidation(true);
        }
      }
  	}
  }

  public setPaymentDefault () {
    if (this.order.store) {
      if (this.order.store.paymentDefault() != null) {
        this.order.payment_method = this.order.store.paymentDefault();
      }
      else if ( !this.order.store.accepts_cash_payments ){
        if ( this.order.store.cardPaymentDefault()){
          this.order.payment_method = this.order.store.cardPaymentDefault()
        }
      }
      else {
        this.order.payment_method = null;
      }
    }
  }

  public setBackToLogin( valor:boolean ):void{
    this.backToLogin = valor;
    localStorage.setItem('back_to_login', valor.toString());
    
  }

  public getBackToLogin():boolean{
    return (localStorage.getItem('back_to_login') == 'true')
  }

  public setUrlDiscount(discount_code:string):void{
    localStorage.setItem('url_discount', discount_code)
  }

  public getUrlDiscount():string{
    return localStorage.getItem('url_discount');
  }

  public  removeUrlDiscount():void{
    localStorage.removeItem('url_discount')
  }

  public getLoginCount():number{
    var lc = localStorage.getItem('login_count');
    return lc == undefined ? 0 : parseInt(lc);
  }

  public setLoginCount(loginCount:number){
    return localStorage.setItem('login_count', loginCount.toString());
  }

  public addLoginCount(){
    let lc:number = this.getLoginCount()
    return this.setLoginCount(lc+1);
  }

  public setCatalog(catalog:string, forced:boolean = false){
    let url_catalog = JSON.parse(localStorage.getItem('url_catalog'))
    if( url_catalog != true || forced){
      if(forced) localStorage.setItem('url_catalog', JSON.stringify(true)) 
      localStorage.setItem('catalog', catalog);
      this.catalog = catalog;
    }
  }

  public getCatalog():string{
    this.catalog = localStorage.getItem('catalog');
    return this.catalog;
  }
  
  public removeCatalog():void{
    localStorage.removeItem('catalog');
    localStorage.setItem('url_catalog',JSON.stringify(false))
  }
  
  public setImpulseBuyViewed(impulseViewed:boolean){
    this.impulseBuyViewed = impulseViewed;
  }

  public getImpulseBuyViewed():boolean{
    return this.impulseBuyViewed;
  }

  public setLoginInModal(value:boolean){
    this.showLoginInModalVal = value;
  }

  public showLoginInModal():boolean{
    return this.showLoginInModalVal;
  }

  public setDefaultTableCode(tableCode:string){
    localStorage.setItem('default_table', tableCode);
    this.defaultTable = tableCode;
  }

  public getDefaultTableCode():string{
    this.defaultTable = localStorage.getItem('default_table');
    return this.defaultTable;
  }

  public removeDefaultTableCode():void{
    localStorage.removeItem('default_table')
  }


  public setDefaultStoreCode(storeCode:string){
    this.defaultStoreCode = storeCode;
  }

  public getDefaultStoreCode():string{
    return this.defaultStoreCode;
  }


  public hasSelectedStore():boolean{
    return this.selectedStoreId != undefined;
  }

  public setSelectedStoreId(storeId:number){
    this.selectedStoreId = storeId;
  }

  public getSelectedStoreId():number{
    return this.selectedStoreId;
  }

  public getSelectedStore():Store{
    return this.getStore(this.selectedStoreId);
  }

  public setStores(stores:Store[]){
    this.stores = stores;
  }

  public getStores():Store[]{
    return this.stores;
  }

  public getStore(store_id:number):Store{
    var index = this.stores.findIndex((s) => s.id == store_id);

    if ( index >= 0) {
      return this.stores[index];
    }

    return undefined;
  }

  public setCurrentStore(store:Store) {
    localStorage.setItem('currentStore', JSON.stringify(store));
  }

  public getCurrentStore() {
    let aux = localStorage.getItem('currentStore');
    this.currentStore = new Store().deserialize(JSON.parse(aux));
    return this.currentStore
  }

  public isRemoteOrder():boolean {
    var remoteOrder = this.getRemoteOrder();

    return remoteOrder != undefined;
  }

  public setRemoteOrder(remoteOrder:any):void{
    return localStorage.setItem('remote_order', JSON.stringify(remoteOrder));
  }

  public getRemoteOrder():RemoteOrder{
    return new RemoteOrder().deserialize(JSON.parse(localStorage.getItem('remote_order')));
  }

  public setAddressAuxFields(address:Address):void {
    localStorage.setItem('aux_province', JSON.stringify(address.aux_province));
    localStorage.setItem('aux_locality', JSON.stringify(address.aux_locality));
  }

  public getAddressAuxFields() {
    var aux_fields = {
      'aux_province' : JSON.parse(localStorage.getItem('aux_province')),
      'aux_locality' : JSON.parse(localStorage.getItem('aux_locality'))
    };

    return aux_fields;
  }

  public clearRemoteOrder():void{
    localStorage.removeItem('remote_order');
  }

  public setAdded(productId:number){
    this.itemAdded = productId;
  }

  public getAdded(){
    var productId = this.itemAdded;
    this.itemAdded = 0;

    return productId;
  }

  public hasKey():boolean{
    return this.getKey() && this.getKey().length > 0
  }

  public generateKey():string {
    // if (this.hasKey()) {
    //   return this.getKey();
    // }

    var localKey = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
    this.setKey(localKey);
    
    return this.getKey();
  }

  public setKey(key:string):void{
    return localStorage.setItem('api_key', key);
  }

  public getKey():string{
    return localStorage.getItem('api_key');
  }

  public setAuthToken(token:string){
    localStorage.setItem('api_token', token);
    this.loggedIn = true;
  }

  public getAuthToken():string{
    return localStorage.getItem('api_token');
  }

  private clearTokens():void{
    localStorage.removeItem('api_token');
    localStorage.removeItem('api_key');
    localStorage.removeItem('current_client');
  }

  private clearProductTypes():void{
    localStorage.removeItem('product_type_list');
  }

  public isLoggedIn():boolean{
    this.loggedIn = (this.getAuthToken() && this.getAuthToken().length > 0);
    
    return this.loggedIn;
  }

  public login(client: Client):boolean{
    this.order.client = client;
    this.loggedIn = true;
    return this.loggedIn;
  }

  public logout(removeOrder:boolean=false):void{
    this.loggedIn = false;
    this.client = new Client();
    this.clearTokens();
    this.clearProductTypes();
    
    if (removeOrder) {
      this.order = new Order();
      var nowSeconds = Math.floor(Date.now()/1000);
      this.order.drafted_at = nowSeconds;
    }

    this.save();
  }

  public setClient(client:Client):void{
    this.client = client;

    if (client && client.getBusiness() && client.getBusiness().hasDiscounts()) {
      this.order.addDiscounts(client.getBusiness().getDiscounts());
    }

    if (client.birthday) {}

    this.save();
  }

  public getClient():Client{
    return this.client;
  }

  public getOrder():Order {
  	return this.order;
  }

  public setOrder(order:Order):Order {
    return this.order = order;
  }

  public getOrderInfoGift() {
    return this.order.order_info_gift;
  }

  public setOrderInfoGift(orderInfoGift:OrderInfoGift):OrderInfoGift {
    // save in storage
    localStorage.setItem('order_info_gift', JSON.stringify(orderInfoGift));

    this.order.order_info_gift = orderInfoGift;

    this.save();

    return this.orderInfoGift = orderInfoGift; 
  }

  public resetOrder():void{
    var nowSeconds = Math.floor(Date.now()/1000);
    this.order.order_details = [];
    this.order.drafted_at = nowSeconds;
    this.save()
  }

  public emptyChart():void{
    this.order.emptyChart();
    this.save();
  }

  public addProduct(product:Product, quantity:number = 1){
  	this.order.addProduct(product, quantity);
  }

  public removeProduct(product:Product, quantity:number = 1){
    this.order.removeProduct(product, quantity);
  }



  public confirmOrder(): void{
    var current_store = this.order.store;
    this.order.confirm();
    this.order = new Order();
    this.order.client = this.client;
    this.catalog = null;
    var nowSeconds = Math.floor(Date.now()/1000);
    this.order.drafted_at = nowSeconds; 
    this.order.store = current_store;
    this.order.store_id = current_store.id;
    this.client.confirmed_orders++;

    if(current_store && current_store.id){
      this.setSelectedStoreId(current_store.id);
    }

    //apply discount
    if (this.client && this.client.getBusiness() && this.client.getBusiness().hasDiscounts()) {
      this.order.addDiscounts(this.client.getBusiness().getDiscounts());
    }

    // clear
    this.clear();
  }

  public setProductTypes(productTypes:ProductType[]):void{
    this.productTypes = productTypes; 
  }

  public getProductTypes() {
    return this.productTypes;
  }

  public setIdSelectProductType(product_type_id:number):void{
    this.idSelectProductType = product_type_id; 
  }

  public getIdSelectProductType() {
    return this.idSelectProductType;
  }

  public clear(){
    var items = ['current_order', 'order_info_gift', 'id_select_product_type', 'default_table', 'catalog'];

    items.map((key) => { localStorage.removeItem(key) });
  }

  public save():void{
    localStorage.setItem('current_order', JSON.stringify(this.order));
    localStorage.setItem('current_client', JSON.stringify(this.client));
    localStorage.setItem('order_info_gift', JSON.stringify(this.orderInfoGift));
    localStorage.setItem('back_to_login', this.backToLogin.toString());
    localStorage.setItem('id_select_product_type', this.idSelectProductType.toString());
  }

  public restore():any {
    var data = {
      order: new Order(),
      client: new Client(),
      productTypes: [],
      order_info_gift: new OrderInfoGift(),
      backToLogin: new Boolean,
      idSelectProductType: new Number
    }

    if (localStorage.getItem('current_order') != null) {
      data.order = new Order().deserialize(JSON.parse(localStorage.getItem('current_order')));
      if (!data.order.drafted_at) {
        var nowSeconds = Math.floor(Date.now()/1000);
        data.order.drafted_at = nowSeconds;
      }

      if(data.order.store != null){
        this.setSelectedStoreId(data.order.store.id);
      }
    } else {
      var nowSeconds = Math.floor(Date.now()/1000);
      data.order.drafted_at = nowSeconds;
    }

    if (localStorage.getItem('current_client') != null) {
      data.client = new Client().deserialize(JSON.parse(localStorage.getItem('current_client')));
      data.order.client = data.client;
    }

    if ((localStorage.getItem('order_info_gift') != null) && (localStorage.getItem('order_info_gift') != 'undefined')) {
      data.order_info_gift = new OrderInfoGift().deserialize(JSON.parse(localStorage.getItem('order_info_gift')));
    }

    var remoteAux = localStorage.getItem('remote_order');
    if (remoteAux != null && remoteAux != undefined) {
      this.remoteOrder = new RemoteOrder().deserialize(JSON.parse(localStorage.getItem('remote_order')));
    }

    if (localStorage.getItem('back_to_login') != null) {
      data.backToLogin = (localStorage.getItem('back_to_login') == "true")
    }

    if (localStorage.getItem('id_select_product_type') != null) {
      data.idSelectProductType = parseInt(localStorage.getItem('id_select_product_type'));
    }

    return data;
  }

  public setVersion(version:string):boolean{
    var current_version = this.getVersion();
    var new_version = false;

    if (current_version && (current_version != version)) {
      localStorage.clear();
      new_version = true;
    }

    localStorage.setItem('version', version);

    return new_version;
  }

  public getVersion():string{
    return localStorage.getItem('version');
  }

  public setFirebaseToken(token:string){
    localStorage.setItem('firebase_token',token)
  }

  public getFirebaseToken():string{
    return localStorage.getItem('firebase_token');
  }

  public setAcceptNotifications(value:boolean){
    localStorage.setItem('accept_notifications',value.toString())
  }

  public getAcceptNotifications():any{
    return localStorage.getItem('accept_notifications');
  }

  // last access
  getLastAccess():any{
    var last_access = localStorage.getItem('last_access');

    localStorage.setItem('last_access', Date.now().toString());

    return last_access;
  }

  // first time
  isFirstTime():boolean{
    return this.getLastAccess() == null;
  }
}
