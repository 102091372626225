import { Deserializable } from "./deserializable.model";

export class StoreRange implements Deserializable {
	id: number;
	name: string;
	day: string;
	store_id: number;
	from_time: string;
	to_time: string;
	deliver_type: string;
	day_i: number;
	time_offset:number;
	overlap_enabled:boolean = false;
	is_available:boolean;
	scheduled_for: string;

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	}

	fromDate():Date {
		return new Date(this.from_time);
	}

	toDate():Date {
		return new Date(this.to_time);
	}

	canUse(product_offset = null):boolean{
		var now = new Date();

		var offseted_time = new Date(this.scheduled_for);

		if (this.overlap_enabled) {
			offseted_time = new Date(this.to_time);	
		}

		var time_offset = this.time_offset;
		if (product_offset) {
			time_offset += product_offset;
		}

		var diff_offseted_time = offseted_time.valueOf() - (time_offset * 60000);

		return diff_offseted_time > now.valueOf();
	}

	private phoursToString(date:string):string{
		var adate = new Date(date);
		var hours = (adate.getHours() < 10 ? '0' : '') + adate.getHours();
		var minutes = (adate.getMinutes() < 10 ? '0' : '') + adate.getMinutes();

		if (adate.getMinutes() > 0) {
			return hours + ":" + minutes + "hs";
		}

		return hours + "hs";

	}

	hoursToString():string{
		if (this.from_time == this.to_time) {
			return this.phoursToString(this.from_time);
		}

		return this.phoursToString(this.from_time) + " a " + this.phoursToString(this.to_time);
	}

	dayOfMonth():Date{
		return new Date(this.scheduled_for);
		
		var today = new Date();
	    var aux = (this.day_i - today.getDay() + 7) % 7;
	    var date = new Date();
	    date.setDate(date.getDate() + aux);

	    return date;
	}

	dayToString():string{
		switch(this.day_i){
		  case 0:
		    return "Domingo";
		    break;
		  case 1:
		    return "Lunes";
		    break;
		  case 2:
		    return "Martes";
		    break;
		  case 3:
		    return "Miércoles";
		    break;
		  case 4:
		    return "Jueves";
		    break;
		  case 5:
		    return "Viernes";
		    break;
		  case 6:
		    return "Sábado";
		    break;
		}
	}
}
