import { Product } from './product.model';
import { Discount } from './discount.model';

export class OrderDetail {
	product: Product;
	quantity: number;
	amount: number;
	discount: number;

	constructor(product:Product, quantity:number, amount:number = null, discount:number = null){
		this.product = product;
		this.quantity = quantity;
		this.discount = discount;
		this.amount = amount;

		//if it has aditionals
		if (amount == null) {
			this.amount = product.price;
			if (product.aditionals != undefined && amount == null) {
				this.amount += product.aditionals.map((x) =>  x.price).reduce((a,b) =>  a+b,0)
			}
		}

		if (discount == null) {
			this.discount = this.amount - product.getFinalAmount();
		}
	}

	public getProduct():Product {
		return this.product;
	}

	public getCode():String {
		return this.product.getCode();
	}

	public getTotalAmount():number{
		return this.product.getFinalAmount() * this.quantity;
	}

	public getAmount():number{
		return this.amount;
	}

	public getDiscount():number{
		return this.discount;
	}

	removeDiscount(discount:Discount) {
		
		var i = this.product.discounts.map(e => e.code).indexOf(discount.code)

		if ( i > -1 )
			this.product.discounts.splice( i, 1 )
	}

	deserialize(input: any) {
	    Object.assign(this, input);
	    if (input.product != undefined) {
	    	this.amount = input.product.price;

	    	this.product = new Product().deserialize(input.product);
	    }else{
	    	this.product = null;
	    }
	    
	    return this;
	}
}
