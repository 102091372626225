import { Deserializable } from "./deserializable.model";
import { Order } from './order.model';
import { Product } from './product.model';

export class Discount implements Deserializable {
    id: number;
    code: string;
    from_date: string;
    from_time: any;
    to_date: string;
    to_time: any;
    max_uses: number;
    max_uses_per_client: number;
    apply_to_all: boolean;
    for_registered_user: boolean;
    first_buy: boolean;
    type: string
    value: number;
    max_amount: number;
    min_amount: number;
    min_product_amount: number;
    name: string;
    description: string;
    buys: string;
    gets: string;
    buys_req_type: string;
    buys_req_value: number;
    gets_benefit_type: string;
    gets_benefit_value: number;
    gets_benefit_article_amount: number;
    gets_benefit_max_amount: number;
    max_product_uses: number;
    client_ids: number[] = [];
    business_ids: number[] = [];
    stores_ids: number[] = [];
    product_ids: number[] = [];
    product_type_ids: number[] = [];
    sub_product_type_ids: number[] = [];
    client_gets_product_ids: number[] = [];
    client_gets_product_type_ids: number[] = [];
    client_gets_sub_product_type_ids: number[] = [];
    client_buys_product_ids: number[] = [];
    client_buys_product_type_ids: number[] = [];
    client_buys_sub_product_type_ids: number[] = [];

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    hasEnding(): boolean {
        return this.to_date != null;
    }

    getMaxAmount(): number {
        return this.max_amount;
    }

    getValue(): number {
        return this.value;
    }

    getMinAmount(): number {
        return this.min_amount;
    }

    getName(): string {
        return this.name;
    }

    getEnding(): string {
        return this.to_date;
    }

    isAvailable(): boolean {

        if (this.from_date) {
            var fromDate = new Date(this.from_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")).getTime();
            var nowFrom = Date.now();
            if (fromDate > nowFrom) {
                return false;
            }
        }

        if (this.to_date) {
            //var toDate = new Date(this.to_date.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")).getTime();
            //var nowTo = Date.now();

            let toDate = (new Date(this.to_date)).toISOString().split('T')[0]
            let nowTo = (new Date()).toISOString().split('T')[0];

            if (toDate < nowTo) {
                return false;
            }

        }

        if (this.from_time) {
            var f = this.from_time.split(':');
            var f_sec = f[0] * 3600 + f[1] * 60 + f[2] * 1;
            var nowFromTime = new Date();
            var f_now = nowFromTime.getHours() * 3600 + nowFromTime.getMinutes() * 60 + nowFromTime.getSeconds();

            if (f_now < f_sec) {
                return false;
            }
        }

        if (this.to_time) {
            var f = this.to_time.split(':');
            var f_sec = f[0] * 3600 + f[1] * 60 + f[2] * 1;
            var nowToTime = new Date();
            var f_now = nowToTime.getHours() * 3600 + nowToTime.getMinutes() * 60 + nowToTime.getSeconds();

            if (f_now > f_sec) {
                return false;
            }
        }

        return true;
    }

    canApplyToOrder(order: Order): boolean {
        let valid = true;
        //Time validation

        if (!this.isAvailable()) {
            valid = false;
        }

        //Client filter
        if (valid && this.client_ids.length > 0) {
            if (this.client_ids.includes(order.client.id)) {
                valid = true;
            } else {
                valid = false;
            }
        }

        //Business filter
        if (valid && this.business_ids.length > 0) {
            if (order.client.id) {
                for (let i = 0; i < order.client.businesses.length; ++i) {
                    let b = order.client.businesses[i];
                    if (this.business_ids.includes(b.id)) {
                        valid = valid && true;
                        break;
                    } else {
                        valid = false;
                    }
                }
            } else {
                valid = false;
            }
        }

        //Store filter
        if (valid && this.stores_ids.length > 0)
            this.stores_ids.includes(order.store_id) ? valid = valid && true : valid = false

        if (this.type != 'buy_a_get_b') {
            //Minimum reqs - Amount
            if (valid && this.min_amount && this.min_amount > 0) {
                if (order.getAmount() < this.min_amount) {
                    valid = false;
                }
            }

            //Minimum reqs - Products
            if (valid && this.min_product_amount && this.min_product_amount > 0) {
                if (order.getQuantity() < this.min_product_amount) {
                    valid = false;
                }
            }
        }

        //Products and ProductTypes
        if (valid && (this.product_ids.length > 0 || this.product_type_ids.length > 0 || this.sub_product_type_ids.length > 0)) {

            if (valid && this.product_ids.length > 0) {
                var od_prods = order.order_details.filter((od) => this.product_ids.includes(od.product.id));
                if (!(od_prods.length > 0)) {
                    valid = false;
                }
            }

            if (valid && this.product_type_ids.length > 0) {
                var od_prods_type = order.order_details.filter((od) => this.product_type_ids.filter((pt_id) => od.product.product_types.includes(pt_id)).length > 0);
                if (!(od_prods_type.length > 0)) {
                    valid = false;
                }
            }

            if (valid && this.sub_product_type_ids.length > 0) {
                var od_sub_prods_type = order.order_details.filter((od) => this.sub_product_type_ids.filter((spt_id) => od.product.sub_product_types.includes(spt_id)).length > 0);
                if (!(od_sub_prods_type.length > 0)) {
                    valid = false;
                }
            }
        }

        //Registered Users

        if (valid && (order.client.is_guest && this.for_registered_user)) {
            valid = false;
        }

        // First Buy


        if (valid) {

            let first_order = order.client.firstOrder();
            if (this.first_buy && !first_order) {
                valid = false;
            }
        }


        return valid;
    }

    getABInfo(order: Order) {
        var data = {
            'buys_a': [],
            'buys_b': [],
            'applies': 0
        };

        order.order_details.forEach((od) => {
            var a_id = [];
            var b_id = [];

            switch (this.buys) {
                case "buy_product_types":
                    a_id = od.product.product_types;
                    break;
                case "buy_sub_product_types":
                    a_id = od.product.sub_product_types;
                    break;
                case "buy_products":
                    a_id = [od.product.id];
                    break;
            }

            switch (this.gets) {
                case "get_product_types":
                    b_id = od.product.product_types;
                    break;
                case "get_sub_product_types":
                    b_id = od.product.sub_product_types;
                    break;
                case "get_products":
                    b_id = [od.product.id];
                    break;
            }

            // genero A con el tipo que sea
            a_id.forEach((aid) => {
                if (data.buys_a[aid] == undefined) {
                    data.buys_a[aid] = {
                        'quantity': 0,
                        'price': 0,
                        'amount': 0
                    }
                }

                data.buys_a[aid].quantity += od.quantity;
                data.buys_a[aid].price += od.product.price;
                data.buys_a[aid].amount += od.amount;
            });

            // genero B con el tipo que sea 
            b_id.forEach((bid) => {
                if (data.buys_b[bid] == undefined) {
                    data.buys_b[bid] = {
                        'quantity': 0,
                        'price': 0,
                        'amount': 0
                    }
                }

                data.buys_b[bid].quantity += od.quantity;
                data.buys_b[bid].price += od.product.price;
                data.buys_b[bid].amount += od.amount;

                // remuevo si A está en B
                var intersect;
                switch (this.buys) {
                    case "buy_product_types":
                        intersect = od.product.product_types.filter((pt) => this.client_buys_product_type_ids.includes(pt));
                        if (intersect.length > 0) {
                            data.buys_b[bid].quantity -= this.buys_req_value;
                            data.buys_b[bid].amount -= this.min_amount;
                        }
                        break;
                    case "buy_sub_product_types":
                        intersect = od.product.sub_product_types.filter((spt) => this.client_buys_sub_product_type_ids.includes(spt));
                        if (intersect.length > 0) {
                            data.buys_b[bid].quantity -= this.buys_req_value;
                            data.buys_b[bid].amount -= this.min_amount;
                        }
                        break;
                    case "buy_products":
                        if (this.client_buys_product_ids.includes(od.product.id)) {
                            data.buys_b[bid].quantity -= this.buys_req_value;
                            data.buys_b[bid].amount -= this.min_amount;
                        }
                        break;
                }
            });
        });

        var a_id = this.client_buys_product_ids.concat(this.client_buys_product_type_ids).concat(this.client_buys_sub_product_type_ids);
        var b_id = this.client_gets_product_ids.concat(this.client_gets_product_type_ids).concat(this.client_gets_sub_product_type_ids);

        if (this.buys_req_type == "min_articles") {
            var a = [];
            var b = [];
            a_id.forEach((aid) => {
                var aux
                data.buys_a.includes(aid) ? aux = (data.buys_a[aid].quantity / this.buys_req_value) : aux = 0
                a.push(aux);
            })

            a_id.forEach((bid) => {
                if (data.buys_b[bid]) {
                    var aux = data.buys_b[bid].quantity / this.gets_benefit_article_amount;
                    b.push(aux);
                }
            })
        }

        return data;

    }

    applyToOrder(order:Order){
		var amount = 0;
		var discount = 0;

		if (!this.canApplyToOrder(order)) {
			return 0;
		}

        //Products and product types
		if (this.product_ids.length || this.product_type_ids.length || this.sub_product_type_ids.length) {
            var od_prods_type = order.order_details.filter((od) => this.product_type_ids.filter((pt_id) => od.product.product_types.includes(pt_id)).length > 0 );
            var od_sub_prods_type = order.order_details.filter((od) => this.sub_product_type_ids.filter((spt_id) => od.product.sub_product_types.includes(spt_id)).length > 0 );
			var od_prods = order.order_details.filter((od) => this.product_ids.includes(od.product.id));

            var all_od_prods = od_prods.concat(od_prods_type).concat(od_sub_prods_type);

            od_prods = [...new Set(all_od_prods)];
			amount = od_prods.reduce((sum, od) => sum + (od.amount * od.quantity), 0);
		} else {
			amount = order.getAmount();
		}

        amount += order.getDeliverCost();

		switch(this.type){	
			case 'percent':
				var already_used = order.client.today_used_discounts;
				var max_available = this.max_amount - already_used;

				discount = amount * this.getValue()/100;

				if ((this.max_amount > 0) && (discount > max_available)) {
					discount = max_available;
				}
			
				break;


           
			case 'amount':
               
				if ((this.min_amount > 0) && (amount > this.min_amount)) {
					// discount = amount - this.min_amount;
					discount = this.value;
				}else{
                    discount = Math.min(this.value, amount);
                }

				break;

            case 'free_shipping':
                discount = order.getDeliverCost();
                break;

            case 'buy_a_get_b':
                let details = order.order_details;

                var buysinfo = this.getABInfo(order);

                switch(this.gets_benefit_type) {
                    case 'benefit_amount':
                        discount = 0;
                        switch(this.gets) {
                            case 'get_products':
                                var totalAmount  = 0;

                                switch(this.buys_req_type) {
                                    case 'min_articles':
                                        var applies = this.checkIfMinArticleQtyOk(this, order);
                                        break;
                                    case 'min_amount':
                                        var applies = this.checkIfMinAmountOk(this, order) === true ? 1 : 0;
                                        break;
                                }

                                if(applies){
                                    details.forEach((od) => {
                                        if(this.client_gets_product_ids.includes(od.product.id)) {
                                            let detailAmount = od.amount * od.quantity
                                            totalAmount = totalAmount + detailAmount;
                                        }
                                    });
                                }
                                break;
                            case 'get_product_types':
                            case 'get_sub_product_types':
                                let canGetProductTypes = this.gets == 'get_product_types' ? this.client_gets_product_type_ids : this.client_gets_sub_product_type_ids;
                                var totalAmount = 0;
                                let maxPerProductType = this.gets_benefit_value;
                                let productTypeAmount = {};
                                let intersect;

                                switch(this.buys_req_type) {
                                    case 'min_articles':
                                        var applies = this.checkIfMinArticleQtyOk(this, order);
                                        break;
                                    case 'min_amount':
                                        var applies = this.checkIfMinAmountOk(this, order) === true ? 1 : 0;
                                        break;
                                }

                                if(applies){
                                    details.forEach((od) => {
                                        if (this.gets == 'get_product_types'){
                                            intersect = od.product.product_types.filter((pt) => canGetProductTypes.includes(pt));
                                        }else{
                                            intersect = od.product.sub_product_types.filter((pt) => canGetProductTypes.includes(pt));
                                        }
                                        if (intersect.length > 0) {
                                            totalAmount += od.amount * od.quantity;                                        
                                        }
                                    });
                                }
                                break;

                        }
                        if (applies) {
                            discount = totalAmount <= this.gets_benefit_value ? totalAmount : this.gets_benefit_value * applies;
                        }

                        break;
                    case 'benefit_percent':
                    case 'benefit_free':
                        switch(this.gets) {
                            case 'get_products':
                                let canGetProducts = this.client_gets_product_ids;
                                var amount = 0;
                                details.forEach((od) => {
                                    if(canGetProducts.includes(od.product.id)) {
                                        if (this.gets_benefit_type == 'benefit_free') {
                                            if (this.gets_benefit_article_amount > od.quantity) {
                                                var quantity = od.quantity;
                                            } else {
                                                var quantity = this.gets_benefit_article_amount;
                                            }
                                        } else {
                                            var quantity = od.quantity;
                                        }
                                        amount += (od.amount * quantity);
                                    }
                                });
                                
                                switch(this.buys_req_type) {
                                    case 'min_articles':
                                        var applies = this.checkIfMinArticleQtyOk(this, order);
                                        break;
                                    case 'min_amount':
                                        var applies = this.checkIfMinAmountOk(this, order) === true ? 1 : 0;
                                        break;
                                }
                                break;
                            case 'get_product_types':
                            case 'get_sub_product_types':
                                let canGetProductTypes = this.gets == 'get_product_types' ? this.client_gets_product_type_ids : this.client_gets_sub_product_type_ids;
                                let intersect;
                                amount = 0;

                                switch(this.buys_req_type) {
                                    case 'min_articles':
                                        var applies = this.checkIfMinArticleQtyOk(this, order);
                                        break;
                                    case 'min_amount':
                                        var applies = this.checkIfMinAmountOk(this, order) === true ? 1 : 0;
                                        break;
                                }

                                if(applies){
                                    details.forEach((od) => {
                                        if (this.gets == 'get_product_types'){
                                            intersect = od.product.product_types.filter((pt) => canGetProductTypes.includes(pt));
                                        }else{
                                            intersect = od.product.sub_product_types.filter((pt) => canGetProductTypes.includes(pt));
                                        }

                                        if (intersect.length > 0) {
                                            amount += (od.amount * od.quantity);
                                        }
                                    });
                                }

                                break;

                        }

                        if (applies) {
                            let discountValue = this.gets_benefit_type == 'benefit_free' ? 100 : this.gets_benefit_value;
                            discount = amount * discountValue/100;
                            if ((this.gets_benefit_max_amount > 0) && (discount > this.gets_benefit_max_amount)) {
                                discount = this.gets_benefit_max_amount;
                            }
                        }

                }
                break;
        }

        return discount;
	}

    checkIfMinAmountOk(discount: Discount, order: Order) {
        let details = order.order_details;
        let detailsAmount = 0;
        switch (this.buys) {
            case 'buy_products':
                let discountProducts = this.client_buys_product_ids;
                details.forEach((od) => {
                    if (discountProducts.includes(od.product.id)) {
                        detailsAmount = detailsAmount + (od.amount * od.quantity);
                    }
                });
                break;
            case 'buy_product_types':
            case 'buy_sub_product_types':
                let discountProductTypes = this.buys == 'buy_product_types' ? this.client_buys_product_type_ids : this.client_buys_sub_product_type_ids;
                let intersect;

                details.forEach((od) => {
                    if (this.buys == 'buy_product_types') {
                        intersect = od.product.product_types.filter((pt) => discountProductTypes.includes(pt));
                    } else {
                        intersect = od.product.sub_product_types.filter((pt) => discountProductTypes.includes(pt));
                    }

                    if (intersect.length > 0) {
                        detailsAmount = detailsAmount + (od.amount * od.quantity)
                    }
                });
                break;

        }
        let amountOk = detailsAmount >= this.buys_req_value ? true : false;
        return amountOk;
    }

    checkIfMinArticleQtyOk(discount:Discount, order:Order) {
        let minArticleQtyOk = 0;
        let details = [];
        
        switch(this.buys) {
            case 'buy_products':
                let mustHaveProducts = this.client_buys_product_ids;
                details = order.order_details;
                let detailsProducts = details.map(function(detail) {
                    return detail.product.id;
                }); 
                let containsAll = mustHaveProducts.every(element => {
                    return detailsProducts.includes(element);
                });

                if (containsAll) {
                    details.forEach((od) => {
                        if (mustHaveProducts.includes(od.product.id) ) {
                                minArticleQtyOk = Math.floor(od.quantity/this.buys_req_value);
                        }
                    });
                }

                break;
            case 'buy_product_types':
            case 'buy_sub_product_types':
                let mustHaveProductTypes = this.buys == 'buy_product_types' ? this.client_buys_product_type_ids : this.client_buys_sub_product_type_ids;
                let actualProductTypes = [];
                let requiredArticleAmount = this.buys_req_type == 'min_articles' ? mustHaveProductTypes.length * this.buys_req_value : 0;
                let actualProducts = 0;
                let boughtProductTypeArticles = {};
                details = order.order_details;

                mustHaveProductTypes.forEach((mhpt) => {
                    boughtProductTypeArticles[mhpt] = 0;
                });

                //Hoy por hoy los productos tienen sólamente un product_type, pero en el admin pueden tener varios.
                let intersect;

                details.forEach((od) => {
                    if (this.buys == 'buy_product_types'){
                        intersect = od.product.product_types.filter((pt) => mustHaveProductTypes.includes(pt));
                    }else{
                        intersect = od.product.sub_product_types.filter((pt) => mustHaveProductTypes.includes(pt));
                    }


                    if (intersect.length > 0) {
                        actualProducts += od.quantity;
                        intersect.forEach((pt_id) => {
                            boughtProductTypeArticles[pt_id] += od.quantity;
                        })
                    }
                });
                
                if (mustHaveProductTypes.length == Object.keys(boughtProductTypeArticles).length) {
                    var boughtProducts = 0;
                    Object.keys(boughtProductTypeArticles).forEach(function(ptid) {
                        let articleAmount = boughtProductTypeArticles[ptid];
                        if (articleAmount == 0) {
                            return false;
                        } 
                        boughtProducts += articleAmount;
                    });
                }

                minArticleQtyOk = Math.floor(boughtProducts/requiredArticleAmount);
                break;

        }
        return minArticleQtyOk;
    }

}
