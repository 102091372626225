import { Order } from './order.model';

export class OrderInfoGift {
	from: string;
	to: string;
	message: string;
	order:Order; 

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	}
}
