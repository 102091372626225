import { Deserializable } from "./deserializable.model";

import { Discount } from "./discount.model";
import { AditionalCategory } from "./aditional-category.model";
import { Aditional } from "./aditional.model";

export class Product implements Deserializable{
	id:number;
	name: string;
	description: string;
	image_url: string;
	image_url_xs: string;
	image_url_s: string;
	image_url_m: string;
	image_url_l: string;
	image_url_xl: string;
	deleted: boolean;
	price: number;
	position: number;
	aditionals: Aditional[] = [];
	ingredients: any[] = [];
	hot: boolean;
	calories: number;
	proteins: number;
	fat: number;
	satured_fat: number;
	fiber: number;
	aditional_categories: AditionalCategory[] = [];
	justAdded: boolean = false;
	has_stock: boolean;
	product_type_id: number;
	sub_product_type_id : number;
	product:Product;
	show_nutritional_info:boolean;
	impulse_buy:boolean = false;
	aditional_category_id:any;
	discounts:Discount[] = [];
	max_products_per_category:number = 0;
	icon:string;
	time_offset:number = 0;
	age_validation:boolean = false;
	available_for_buy:boolean = true;
	from_date:any;
	to_date:any;
	pre_sale:boolean = false;
	quantity:number = 0;
	product_types:any = [];
	sub_product_types:any = [];

	value_amount = 0;
	value_discount = 0;

	hasAgeValidation(){
		return this.age_validation;
	}

	hasDiscount(discount:Discount){
		var index = this.discounts.findIndex((d) => d.id == discount.id);

		return index >= 0;
	}

	hasDiscounts(){
		this.discounts = this.discounts.filter((d) => d.isAvailable());

		return this.discounts.length > 0;
	}

	addDiscount(discount:Discount){
		if (!this.hasDiscount(discount)){
			this.discounts.push(discount);
		}
	}
 
	public applyDiscount(discount:Discount){
		if (this.hasDiscount(discount)) {
			return;
		}

		if (discount.type == 'percent') {
			// check for products
			if (discount.product_ids.length > 0) {
				if (discount.product_ids.includes(this.id)) {
					this.addDiscount(discount);
				}
			}

			// if it has product types
			if (discount.product_type_ids.length > 0 && this.product_types.length > 0){
				var join_pts = discount.product_type_ids.filter((pt_id) => this.product_types.includes(pt_id));
				if(join_pts.length > 0){
					this.addDiscount(discount);
				}
			}

			// if it has sub product types
			if (discount.sub_product_type_ids.length > 0 && this.sub_product_types.length > 0){
				var join_spts = discount.sub_product_type_ids.filter((pt_id) => this.sub_product_types.includes(pt_id));
				if(join_spts.length > 0){
					this.addDiscount(discount);
				}
			}
		}
	}

	public removeAllDiscount() {
		this.discounts = [];
	}

	public getFinalPercentDiscount(){
		if (!this.hasDiscounts()) {
			return;
		}

		var percent = 1;

		this.discounts.forEach((d) => {
			percent = percent * (1 - d.getValue()/100);
		});

		return Math.round((1 - percent)*100);
	}

	public getFinalAmount(){
		var amount = this.getAmount();

		if (this.hasDiscounts()) {
			this.discounts.forEach((d) => {
				amount = amount * (1 - d.getValue()/100);
			})

			return amount;
		}

		return amount;
	}

	public getFinalAmountForPrice(price:number){
		if (this.hasDiscounts()) {
			this.discounts.forEach((d) => {
				price = price * (1 - d.getValue()/100);
			})

			return price;
		}

		return price;
	}

	public getCode(): string{
		let c:any[] = [];

		//get id
		c.push(this.id);

		//get aditionals id if it has any
		if (this.aditionals != undefined) {
			c = c.concat(
				this.aditionals.map(function(x){ return x.id }).sort()
			);
		}

		return c.join("-");
	}

	public getCodeWithAditionalProductIds(): string{
		let c:any[] = [];

		//get id
		c.push(this.id);

		//get aditionals id if it has any
		if (this.aditionals != undefined) {
			c = c.concat(
				this.aditionals.map(function(x){ return x.product.id }).sort()
			);
		}

		return c.join("-");
	}
	
	getAmount() : number {
		let amount : number = this.price;

		if (this.aditionals != undefined) {
			amount += this.aditionals.map((product) => product.price * product.quantity).reduce((a,b) => a+b,0)
		}

		return amount;
	}

	isPreSale() : boolean {
		return this.pre_sale;
	}

	deserialize(input: any) {
	    Object.assign(this, input);
	    if (input.aditionals != undefined) {
	    	this.aditionals = input.aditionals.map( (prod) => new Product().deserialize(prod) );	
	    }else{
	    	this.aditionals = [];
	    }

	    if (input.discounts != undefined) {
	    	this.discounts = input.discounts.map( (discount) => new Discount().deserialize(discount) );	
	    }else{
	    	this.discounts = [];
	    }

	    if (input.aditional_categories != undefined) {
	    	this.aditional_categories = input.aditional_categories.map( (aditional_category) => new AditionalCategory().deserialize(aditional_category) );	
	    }else{
	    	this.aditional_categories = [];
	    }

	    

		var now = new Date();
	    if (input.from_date){
	    	this.from_date = new Date(input.from_date);
    		this.pre_sale = (this.from_date > now);
	    }

	    if (input.to_date){
	    	this.to_date = new Date(input.to_date);
	    	if (this.to_date < now){
	    		this.has_stock = false;
	    	}
	    }

	    return this;
	}

	hasAditionals() : boolean{
		return this.aditional_categories.length > 0;
	}

	getCheckoutDescription() : string {
		if (this.aditionals.length > 0) {
			let description = this.aditionals.map(ad => ad.quantity > 1 ? ad.name + "  (" + ad.quantity + ")" : ad.name );

			return description.join(", ");
		}

		// return this.description;
		// return nothing
		return "";
	}
}
