import { Deserializable } from "./deserializable.model";

export class StoreHour implements Deserializable {
	name: string;
	day: string;
	from_time: string;
	to_time: string;
	day_i: number;

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	}

	to_string():string{
		var from = new Date(this.from_time);
		var to = new Date(this.to_time);

		return from.getHours() + "hs a " + to.getHours() + "hs";
	}
}
