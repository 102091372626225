import { Deserializable } from "./deserializable.model";

export class RemoteOrder implements Deserializable {
	order:string;
    hwd:string;

	deserialize(input: any) {
	    Object.assign(this, input);

        return this;
	}
}
