
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ProductType } from '../models/product-type.model'
import { Product } from '../models/product.model'
import { Order } from '../models/order.model'
import { OrderInfoGift } from '../models/order-info-gift.model'
import { Store } from '../models/store.model'
import { Address } from '../models/address.model'
import { Client } from '../models/client.model'
import { Table } from '../models/table.model'
import { Discount } from '../models/discount.model'
import { StoreRange } from '../models/store-range.model'

import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CoreService {
  private base_url:string = environment.core_server_url;
  private token:string = '';

  constructor(
  	protected httpClient: HttpClient
  ) { }

  setAuthToken(token:string){
    this.token = token;
  }

  getClientCoupons() : Observable<any> {
    return this.httpClient.get<Discount>(this.base_url + '/clients/coupons', { 'headers': this.getHeaders(this.token) });
  }

  addClientCoupon(code:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/clients/coupons', { 'code': code }, { 'headers': this.getHeaders(this.token) });
  }
  
  getCoupon(code:string) : Observable<any> {
    return this.httpClient.get<Discount>(this.base_url + '/coupons/' + code, { 'headers': this.getHeaders(this.token) });
  }

  getAvailableCoupons() : Observable<any> {
    return this.httpClient.get<Discount>(this.base_url + '/coupons/available', { 'headers': this.getHeaders(this.token) });
  }

  getDeliverDates(store_id:number, type:string, for_date:string) : Observable<StoreRange[]>{
    return this.httpClient.get<StoreRange[]>(this.base_url + '/deliver_ranges/?deliver_type=' + type + '&store=' + store_id + '&date=' + for_date);
  }

  getDeliverRanges(deliver_type:string, province_id:string = null, locality_id:string = null, store_id:string = null, for_date:string = null) : Observable<any> {
    let url = this.base_url + '/deliver_ranges/?deliver_type=' + deliver_type;

    if (store_id != null) {
      url += "&store=" + store_id;
    }

    if (province_id != null) {
      url += "&province=" + province_id;
    }

    if (locality_id != null) {
      url += "&locality=" + locality_id;
    }

    if (for_date != null) {
      url += "&date=" + for_date;
    }

    return this.httpClient.get<any>(url);
  }

  getProductTypes(withProducts:boolean = false, storeId:number = null, catalog:string = null) : Observable<ProductType[]> {
    let url = '/product_types/all';

    if (catalog != null) {
      url += "/catalog/" + catalog;
    }

    if (storeId != null) {
      url += "/store/" + storeId;
    }

    if (withProducts != null) {
      url += "/with_products/" + (withProducts ? 'true' : 'false');
    }

    // url += (withProducts ? 'true' : 'false');
    return this.httpClient.get<ProductType[]>(this.base_url + url + ".json");
  }

  getStores(all=false) : Observable<Store[]> {
    let url = this.base_url + '/stores';
    if(all){
      url = url + '/all';
    }
    return this.httpClient.get<Store[]>(url);
  }

  getStore(store_id:number) : Observable<Store> {
    return this.httpClient.get<Store>(this.base_url + '/stores/' + store_id);
  }

  getStoreByCode(store_code:number) : Observable<Store> {
    return this.httpClient.get<Store>(this.base_url + '/stores/code/' + store_code);
  }

  getTables(store_id:number) : Observable<Table[]> {
    return this.httpClient.get<Table[]>(this.base_url + '/stores/' + store_id + '/tables');
  }

  getTableSectors(store_id:number) : Observable<any[]> {
    return this.httpClient.get<Table[]>(this.base_url + '/stores/' + store_id + '/sectors');
  }

  getTable(store_id:number, table_code:string, client_id:number = null) : Observable<Table> {
    return this.httpClient.get<Table>(this.base_url + '/stores/' + store_id + '/table/' + table_code + "?client=" + client_id);
  }

  reserveTable(store_id:number, table_code:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/stores/' + store_id + '/table/' + table_code + '/reserve', { 'headers': this.getHeaders(this.token) });
  }

  searchProducts(q:string, store_id:number = null, catalog:string = null) : Observable<ProductType> {
    var url = this.base_url + '/products/search?q=' + q;

    if (catalog != null) {
      url += "&catalog=" + catalog;
    }

    if (store_id != null) {
      url += "&store=" + store_id;
    }

    return this.httpClient.get<ProductType>(url);
  }

  getProducts(product_type_id:number, store_id:number = null, catalog:string = null) : Observable<ProductType> {
    var url = this.base_url + '/product_types';

    if (catalog != null) {
      url += "/catalog/" + catalog;
    }

    url += "/" + product_type_id;

    if (store_id != null) {
      url += "/store/" + store_id;
    }

    return this.httpClient.get<ProductType>(url + ".json", { 'headers': this.getHeaders() });
  }

  getProductType(product_type_id:number, store_id:number = null, catalog:string = null) : Observable<ProductType> {
    var url = this.base_url + "/product_types"; 

    if (catalog != null) {
      url += "/catalog/" + catalog;
    }

    url += '/' + product_type_id;

    if (store_id != null) {
      url += "/store/" + store_id;
    }

    url += "/only_header";

    return this.httpClient.get<ProductType>(url + ".json", { 'headers': this.getHeaders() });
  }

  getProduct(product_id:number, store_id:number = null) : Observable<Product> {
    var url = this.base_url + '/products/' + product_id + (store_id != null ? '?store=' + store_id : '');
    return this.httpClient.get<Product>(url);
  }

  getImpulseProducts(order:Order,store_id = null) : Observable<Product[]> {
    var product_ids = "";


    product_ids = order.order_details.map((od) => od.product.id).join(",")

    let endpoint = `${this.base_url}/coeus/products?product_ids=${product_ids}&max_products=3`;

    if ( store_id ) endpoint = `${endpoint}&store=${store_id}`;

    return this.httpClient.get<Product[]>( endpoint, { 'headers': this.getHeaders() });
  }

  confirmOrder(order:Order) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/orders', { 'order': order }, { 'headers': this.getHeaders(this.token) });
  }

  confirmOrderInfoGift(orderInfoGift:OrderInfoGift) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/order_info_gifts', { 'order_info_gift': orderInfoGift }, { 'headers': this.getHeaders(this.token) });
  }

  validateOrder(order:Order, catalog:string = null) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/orders/validate', { 'order': order, 'catalog': catalog }, { 'headers': this.getHeaders(this.token) });
  }

  getBill(hash_id:string) : Observable<any> {
    return this.httpClient.get<Order>(this.base_url + '/orders/bill/' + hash_id, { 'headers': this.getHeaders(this.token) });
  }

  getOrder(id:string) : Observable<Order> {
    return this.httpClient.get<Order>(this.base_url + '/orders/' + id, { 'headers': this.getHeaders(this.token) });
  }

  getOrders(page:number) : Observable<Order[]> {
    return this.httpClient.get<Order[]>(this.base_url + '/clients/orders/?page=' + page, { 'headers': this.getHeaders(this.token) });
  }

  deliverOrder(id:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/orders/deliver', { 'hash_id': id }, { 'headers': this.getHeaders(this.token) });
  }

  getStatusMP(id:string) : Observable<Order> {
    return this.httpClient.get<Order>(this.base_url + '/integrations/mp/check_status/' + id, { 'headers': this.getHeaders(this.token) });
  }

  getClientAddresses() : Observable<Address[]> {
    return this.httpClient.get<Address[]>(this.base_url + '/clients/addresses', { 'headers': this.getHeaders(this.token) });
  }

  getClient() : Observable<Client> {
    return this.httpClient.get<Client>(this.base_url + '/clients', { 'headers': this.getHeaders(this.token) });
  }

  getClientStatus() : Observable<any> {
    return this.httpClient.get<any>(this.base_url + '/clients/status', { 'headers': this.getHeaders(this.token) });
  }

  getPendingOrders(page:number) : Observable<Order[]> {
    return this.httpClient.get<Order[]>(this.base_url + '/clients/orders/pendings', { 'headers': this.getHeaders(this.token) });
  }

  getAuthToken(key:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/authentication', {'key': key});
  }

  getLatLong(address:Address) : Observable<any> {
    return this.httpClient.get<any>("http://servicios.usig.buenosaires.gob.ar/normalizar/?direccion=" + address.street + "&geocodificar=TRUE");
  }

  getProvinces() : Observable<any> {
    return this.httpClient.get<any>(this.base_url + '/geo/provinces/1');
  }

  getLocalities(province_id:string) : Observable<any> {
    return this.httpClient.get<any>(this.base_url + '/geo/province/' + province_id);
  }

  normalizarDireccionCABA(address:Address) : Observable<any> {
    return this.httpClient.get<any>("https://apis.datos.gob.ar/georef/api/direcciones?direccion=" + address.street + "&provincia=" + address.province);
  }

  normalizarDireccion(address:Address) : Observable<any> {
    return this.httpClient.get<any>("https://apis.datos.gob.ar/georef/api/direcciones?direccion=" + address.street + "&departamento=" + address.locality + "&provincia=" + address.province);
  }

  updateClient(
    first_name:String,
    last_name:String,
    nickname:String,
    sex:Boolean,
    email:String,
    phone:String,
    birthday:Date,
    document_type:String,
    document_number:String,
    licence_plate:String
  ) : Observable<any> {
    return this.httpClient.put<any>(this.base_url + '/clients', {
      first_name : first_name,
      last_name : last_name,
      nickname : nickname,
      sex : sex,
      email : email,
      phone : phone,
      document_type : document_type,
      birthday : birthday,
      document_number : document_number,
      licence_plate : licence_plate,
    }, { 'headers': this.getHeaders(this.token) });
  }

  updatePhone(phone:any) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/clients/update_phone', { phone: phone }, { 'headers': this.getHeaders(this.token) });
  }

  updateNickname(nickname:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/clients/update_nickname', { nickname: nickname }, { 'headers': this.getHeaders(this.token) });
  }

  updateEmail(email:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/clients/update_email', { email: email }, { 'headers': this.getHeaders(this.token) });
  }

  updateLicencePlate(licence_plate:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/clients/update_licenceplate', { licence_plate: licence_plate }, { 'headers': this.getHeaders(this.token) });
  }

  updateFirebaseToken(token:string) : Observable<any> {
    
    return this.httpClient.post<any>(this.base_url + '/clients/update_firebase_token', { firebase_token: token}, { 'headers': this.getHeaders(this.token) });
  }

  pairRemoteDevice(hash_id:string, hwd_id:string) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/remote/pair_device', {hash: hash_id, hwd: hwd_id} , { 'headers': this.getHeaders(this.token) });
  }

  getStatusRemoteDevice(token:string) : Observable<any> {
    return this.httpClient.get<any>(this.base_url + '/remote/get_status', { 'headers': this.getHeaders(token) });
  }

  getPoll(name:string) : Observable<any> {
    return this.httpClient.get<any>(this.base_url + '/polls/' + name, { 'headers': this.getHeaders(this.token) });
  }

  getAds(route:string, app:string) : Observable<any> {
    return this.httpClient.get<any>(this.base_url + '/ads/get_by_route_app?route=' + route + '&app=' + app, { 'headers': this.getHeaders(this.token) });
  }

  savePollResponse(name:string, poll_answer:string = null, screen:string = null) : Observable<any> {
    return this.httpClient.post<any>(this.base_url + '/polls/' + name, {poll_answer: poll_answer, screen: screen} , { 'headers': this.getHeaders(this.token) });
  }

  getHeaders(token:string = null) : HttpHeaders {
    var headers = new HttpHeaders({});

    if (token && token.length > 0) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      });
    }else{
      headers = new HttpHeaders({});
    }

    return headers;
  }

}
