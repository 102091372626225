import { Deserializable } from "./deserializable.model";

export class Table implements Deserializable {
	name: string;
	status: string;
	code: string;
	enabled: boolean;
	expire_at: string;
	area_type: string;
	table_sector: string = "";

	deserialize(input: any) {
	    Object.assign(this, input);
	    return this;
	}

	isOccupied():boolean{
		return !this.isFree();
	}

	isFree():boolean{
		return this.status == 'free';
	}

	isTable():boolean{
		return this.area_type == 'tables';
	}

	isFuelPump():boolean{
		return this.area_type == 'fuel_pump';
	}

	getName():string{
		if(this.table_sector && this.table_sector.length > 0){
			return this.name + " (" + this.table_sector + ")";
		}

		return this.name;
	}

	private testName(search_for:string):any{
		var name = this.name.toLowerCase();
		var pattern = new RegExp("(.*)?" + search_for.toLowerCase() + "(.*)");

		return pattern.test(name);
	}
}
