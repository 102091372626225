import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import firebase from "firebase/compat"
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  messagingFirebase: firebase.messaging.Messaging;
  permission:string;

  constructor() { 
    if(environment.push_notification.enabled){
      firebase.initializeApp(environment.firebase);
      this.messagingFirebase = firebase.messaging();
    }
  }

  requestPermission = () =>{
    return new Promise(async (resolve,reject)=>{
      const permission = await Notification.requestPermission(); // request permision to browser
      if (permission === "granted"){
        const tokenFirebase = await this.messagingFirebase.getToken();
        return resolve(tokenFirebase);
      }else{
        reject(console.error("No se otorgaron los permisos para las notificaciones"))
      }
    })
  }

  private messagingObservable = new Observable( observe =>{
    this.messagingFirebase.onMessage(payload =>{
      observe.next(payload)
    })
  })

  receiveMessage(){
    this.messagingObservable;
  }


}
