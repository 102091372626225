import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./init/init.module').then( m => m.InitPageModule)
  }, 
  {
    path: 'home-v1',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./productos/productos.module').then( m => m.ProductosPageModule)
  },
  {
    path: 'detalle-producto',
    loadChildren: () => import('./detalle-producto/detalle-producto.module').then( m => m.DetalleProductoPageModule)
  },
  {
    path: 'checkout-entrega',
    loadChildren: () => import('./checkout-entrega/checkout-entrega.module').then( m => m.CheckoutEntregaPageModule)
  },
  {
    path: 'checkout-horario',
    loadChildren: () => import('./checkout-horario/checkout-horario.module').then( m => m.CheckoutHorarioPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'mis-pedidos',
    loadChildren: () => import('./mis-pedidos/mis-pedidos.module').then( m => m.MisPedidosPageModule)
  },
  {
    path: 'mis-favoritos',
    loadChildren: () => import('./mis-favoritos/mis-favoritos.module').then( m => m.MisFavoritosPageModule)
  },
  {
    path: 'bienvenida',
    loadChildren: () => import('./bienvenida/bienvenida.module').then( m => m.BienvenidaPageModule)
  },
  {
    path: 'redirect-mp',
    loadChildren: () => import('./redirect-mp/redirect-mp.module').then( m => m.RedirectMpPageModule)
  },
  {
    path: 'confirmacion',
    loadChildren: () => import('./confirmacion/confirmacion.module').then( m => m.ConfirmacionPageModule)
  },
  {
    path: 'adicionales',
    loadChildren: () => import('./adicionales/adicionales.module').then( m => m.AdicionalesPageModule)
  },
  {
    path: 'proceso',
    loadChildren: () => import('./proceso/proceso.module').then( m => m.ProcesoPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'integracion',
    loadChildren: () => import('./integracion/integracion.module').then( m => m.IntegracionPageModule)
  },
  {
    path: 'pedido-en-curso',
    loadChildren: () => import('./pedido-en-curso/pedido-en-curso.module').then( m => m.PedidoEnCursoPageModule)
  },
  {
    path: 'pedidos-en-curso',
    loadChildren: () => import('./pedidos-en-curso/pedidos-en-curso.module').then( m => m.PedidosEnCursoPageModule)
  },
  {
    path: 'checkout-store',
    loadChildren: () => import('./checkout-store/checkout-store.module').then( m => m.CheckoutStorePageModule)
  },
  {
    path: 'checkout-pago',
    loadChildren: () => import('./checkout-payment/checkout-payment.module').then( m => m.CheckoutPaymentPageModule)
  },
  {
    path: 'pedido',
    loadChildren: () => import('./confirmacion/confirmacion.module').then( m => m.ConfirmacionPageModule)
  },
  {
    path: 'legales',
    loadChildren: () => import('./legales/legales.module').then( m => m.LegalesPageModule)
  },
  {
    path: 'mi-cuenta',
    loadChildren: () => import('./mi-cuenta/mi-cuenta.module').then( m => m.MiCuentaPageModule)
  },
  {
    path: 'pos-integration',
    loadChildren: () => import('./pos-integration/pos-integration.module').then( m => m.PosIntegrationPageModule)
  },
  {
    path: 'checkout-method',
    loadChildren: () => import('./checkout-method/checkout-method.module').then( m => m.CheckoutMethodPageModule)
  },
  {
    path: 'user-nickname',
    loadChildren: () => import('./user-nickname/user-nickname.module').then( m => m.UserNicknamePageModule)
  },
  {
    path: 'asistencia',
    loadChildren: () => import('./asistencia/asistencia.module').then( m => m.AsistenciaPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'user-email',
    loadChildren: () => import('./user-email/user-email.module').then( m => m.UserEmailPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout-v2/checkout-v2.module').then( m => m.CheckoutV2PageModule)
  },
  {
    path: 'minimum-amount-box',
    loadChildren: () => import('./components/minimum-amount-box/minimum-amount-box.module').then( m => m.MinimumAmountBoxPageModule)
  },
  {
    path: 'integracion',
    loadChildren: () => import('./integracion/integracion.module').then( m => m.IntegracionPageModule)
  },
  {
    path: 'pedido-en-curso',
    loadChildren: () => import('./pedido-en-curso/pedido-en-curso.module').then( m => m.PedidoEnCursoPageModule)
  },
  {
    path: 'pedidos-en-curso',
    loadChildren: () => import('./pedidos-en-curso/pedidos-en-curso.module').then( m => m.PedidosEnCursoPageModule)
  },
  {
    path: 'checkout-store',
    loadChildren: () => import('./checkout-store/checkout-store.module').then( m => m.CheckoutStorePageModule)
  },
  {
    path: 'checkout-pago',
    loadChildren: () => import('./checkout-payment/checkout-payment.module').then( m => m.CheckoutPaymentPageModule)
  },
  {
    path: 'pedido',
    loadChildren: () => import('./confirmacion/confirmacion.module').then( m => m.ConfirmacionPageModule)
  },
  {
    path: 'legales',
    loadChildren: () => import('./legales/legales.module').then( m => m.LegalesPageModule)
  },
  {
    path: 'mi-cuenta',
    loadChildren: () => import('./mi-cuenta/mi-cuenta.module').then( m => m.MiCuentaPageModule)
  },
  {
    path: 'pos-integration',
    loadChildren: () => import('./pos-integration/pos-integration.module').then( m => m.PosIntegrationPageModule)
  },
  {
    path: 'user-nickname',
    loadChildren: () => import('./user-nickname/user-nickname.module').then( m => m.UserNicknamePageModule)
  },
  {
    path: 'asistencia',
    loadChildren: () => import('./asistencia/asistencia.module').then( m => m.AsistenciaPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'user-email',
    loadChildren: () => import('./user-email/user-email.module').then( m => m.UserEmailPageModule)
  },   
  {
    path: 'home',
    loadChildren: () => import('./home-extended/home-extended.module').then( m => m.HomeExtendedPageModule)
  },
  {
    path: 'poll',
    loadChildren: () => import('./poll/poll.module').then( m => m.PollPageModule)
  },
  {
    path: 'busqueda',
    loadChildren: () => import('./busqueda/busqueda.module').then( m => m.BusquedaPageModule)
  },
  {
    path: 'mis-cupones',
    loadChildren: () => import('./mis-cupones/mis-cupones.module').then( m => m.MisCuponesPageModule)
  },
  {
    path: 'select-store',
    loadChildren: () => import('./select-store-v2/select-store-v2.module').then( m => m.SelectStoreV2PageModule)
  },
  {
    path: 'checkout-table',
    loadChildren: () => import('./checkout-table/checkout-table.module').then( m => m.CheckoutTablePageModule)
  },
  {
    path: 'factura',
    loadChildren: () => import('./factura/factura.module').then( m => m.FacturaPageModule)
  },
  {
    path: 'checkout-pago-card',
    loadChildren: () => import('./checkout-payment-card/checkout-payment-card.module').then( m => m.CheckoutPaymentCardPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./init/init.module').then( m => m.InitPageModule)
  },


  // {
  //   path: 'dni',
  //   loadChildren: () => import('./dni/dni.module').then( m => m.DniPageModule)
  // },
  // {
  //   path: 'ingresar',
  //   loadChildren: () => import('./ingresar/ingresar.module').then( m => m.IngresarPageModule)
  // },
  // {
  //   path: 'register',
  //   loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  // },
  // {
  //   path: 'register-success',
  //   loadChildren: () => import('./register-success/register-success.module').then( m => m.RegisterSuccessPageModule)
  // },
  // {
  //   path: 'forgot-pass-success',
  //   loadChildren: () => import('./forgot-pass-success/forgot-pass-success.module').then( m => m.ForgotPassSuccessPageModule)
  // },
  // {
  //   path: 'forgot-pass',
  //   loadChildren: () => import('./forgot-pass/forgot-pass.module').then( m => m.ForgotPassPageModule)
  // },
  // {
  //   path: 'modal-usuario',
  //   loadChildren: () => import('./modal-usuario/modal-usuario.module').then( m => m.ModalUsuarioPageModule)
  // },
  // {
  //   path: 'product-card',
  //   loadChildren: () => import('./components/product-card/product-card.module').then( m => m.ProductCardPageModule)
  // },
  // {
  //   path: 'modal-impulse-buy',
  //   loadChildren: () => import('./modals/modal-impulse-buy/modal-impulse-buy.module').then( m => m.ModalImpulseBuyPageModule)
  // },
  // {
  //   path: 'modal-unavailable-products',
  //   loadChildren: () => import('./modals/modal-unavailable-products/modal-unavailable-products.module').then( m => m.ModalUnavailableProductsPageModule)
  // },
  // {
  //   path: 'banner-home',
  //   loadChildren: () => import('./components/banner-home/banner-home.module').then( m => m.BannerHomePageModule)
  // },
  // {
  //   path: 'promo-slider',
  //   loadChildren: () => import('./components/promo-slider/promo-slider.module').then( m => m.PromoSliderPageModule)
  // },
  // {
  //   path: 'product-card-horizontal',
  //   loadChildren: () => import('./components/product-card-horizontal/product-card-horizontal.module').then( m => m.ProductCardHorizontalPageModule)
  // },
  // {
  //   path: 'message',
  //   loadChildren: () => import('./message/message.module').then( m => m.MessagePageModule)
  // },
  // {
  //   path: 'modal-register',
  //   loadChildren: () => import('./modals/modal-register/modal-register.module').then( m => m.ModalRegisterPageModule)
  // },
  // {
  //   path: 'modal-sign-account',
  //   loadChildren: () => import('./modals/modal-sign-account/modal-sign-account.module').then( m => m.ModalSignAccountPageModule)
  // },
  // {
  //   path: 'coupon-card',
  //   loadChildren: () => import('./components/coupon-card/coupon-card.module').then( m => m.CouponCardPageModule)
  // },
  // {
  //   path: 'modal-delete-products',
  //   loadChildren: () => import('./modals/modal-delete-products/modal-delete-products.module').then( m => m.ModalDeleteProductsPageModule)
  // },
  // {
  //   path: 'modal-info',
  //   loadChildren: () => import('./modals/modal-info/modal-info.module').then( m => m.ModalInfoPageModule)
  // },
  // {
  //   path: 'modal-notification',
  //   loadChildren: () => import('./modals/modal-notification/modal-notification.module').then( m => m.ModalNotificationPageModule)
  // },
  // {
  //   path: 'modal-impulse-buy',
  //   loadChildren: () => import('./modals/modal-impulse-buy/modal-impulse-buy.module').then( m => m.ModalImpulseBuyPageModule)
  // },
  // {
  //   path: 'modal-unavailable-products',
  //   loadChildren: () => import('./modals/modal-unavailable-products/modal-unavailable-products.module').then( m => m.ModalUnavailableProductsPageModule)
  // },
  // {
  //   path: 'modal-discount',
  //   loadChildren: () => import('./modals/modal-discount/modal-discount.module').then( m => m.ModalDiscountPageModule)
  // },
  // {
  //   path: 'modal-gift-message',
  //   loadChildren: () => import('./modals/modal-gift-message/modal-gift-message.module').then( m => m.ModalGiftMessagePageModule)
  // },
  // {
  //   path: 'modal-comment',
  //   loadChildren: () => import('./modals/modal-comment/modal-comment.module').then( m => m.ModalCommentPageModule)
  // },
  // {
  //   path: 'product-card',
  //   loadChildren: () => import('./components/product-card/product-card.module').then( m => m.ProductCardPageModule)
  // },
  // {
  //   path: 'section-carrousel',
  //   loadChildren: () => import('./components/section-carrousel/section-carrousel.module').then( m => m.SectionCarrouselPageModule)
  // },
  // {
  //   path: 'home-extended-loading',
  //   loadChildren: () => import('./components/home-extended-loading/home-extended-loading.module').then( m => m.HomeExtendedLoadingPageModule)
  // },
  // {
  //   path: 'select-store-v1',
  //   loadChildren: () => import('./select-store/select-store.module').then( m => m.SelectStorePageModule)
  // },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
