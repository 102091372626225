import { Deserializable } from "./deserializable.model";

import { Aditional } from "./aditional.model";

export class AditionalCategory implements Deserializable{
	id:string;
	name: string;
	description: string;
	external_id: string;
	mandatory:boolean = false;
	max_quantity:number = 0;
	min_quantity:number = 0;
	multiple:boolean = false;
	aditionals:Aditional[] = [];
	recipe_id:number;

	deserialize(input: any) {
	    Object.assign(this, input);
	    if (input.aditionals != undefined) {
	    	this.aditionals = input.aditionals.map( (aditional) => new Aditional().deserialize(aditional) );	
	    }else{
	    	this.aditionals = [];
	    }

	    return this;
	}
}
