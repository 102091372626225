export const environment = {
  production: false,
  //core URl
  // core_server_url: 'http://core.andco.com.ar:3000',
  core_server_url: 'https://core-axion.idiotlab.co/api/v1',
  login_url:       'https://core-axion.idiotlab.co/users/login',
  webapp_url:      'https://store-axion.idiotlab.co',
  base_path:       '/',
  app_name:        'I+DIoT Lab',

  //attemps to check payment
  payment_check_attemps: 10,
  //time between payment checks in milliseconds
  payment_check_timeout: 1000,
  //type of app
  webapp: true,
  mapbox:{
    accessToken: 'pk.eyJ1IjoicGFicmEiLCJhIjoiY2tmZnM0MzJzMGh0dTJ4bG15MTd3cHcxYiJ9.uSkQxxMNEF7Wqh5jyz8MJA',
    default_zoom: 15,
    not_found_zoom: 14
  },

  // pattern for scan
  table_scan: {
    pattern: "\/store\/(.+)\/table\/(.+)"
  },

  // pattern for unlock_code
  unlock_code: {
    pattern: "^\\d{7,9}$"
  },

  // stores config
  stores: {
    map: {
      // min quantity to show map
      min_quantity: 1
    },

    // force select store at the beggining
    force_select: true
  },

  force_unique_product_type: false,

  // show my coupons
  enable_my_coupons: true,

  // interval for retrieving pending orders
  pending_order_interval: 5,

  //change link to force open MP APP
  mp_force_app: true,

  // when ready, if modal then interval for check status
  order_ready_modal: {
    interval_status: 2
  },

  // Analytics
  analytics: {
    enabled: true
  },

  facebook: {
    enabled: false
  },

  // App is out of service
  out_of_service: false,

  draft_timeout : 21600,

  poll: {
    name: 'satisfaction'
  },

  age_validation: {
    min_years: 18
  },

  force_login: false,

  products:{
    force_view: false
  },

  onboarding:{
    enabled: false,
    timeout: 15,
    video_path: "/assets/video/onboarding.mp4"
  },

  push_notification: {
    enabled: false
  },

  firebase: {
    apiKey: "AIzaSyCIn032IvPBZ9ujX-2aOa1KwuPpPCLUP-w",
    authDomain: "marianpushweb.firebaseapp.com",
    projectId: "marianpushweb",
    storageBucket: "marianpushweb.appspot.com",
    messagingSenderId: "616274916864",
    appId: "1:616274916864:web:4fd41f3599d7d8be7f4ae5",
    vapidKey: "BO5dXbhpmBaLyAP91IbTd83WDm0shkTSJgFAxHts7mMataAKqIimB2ubQGTAQ0s1nXR8I5V3_MOgqVIwrhHXrjo"
  },

  modo: {
    url: "https://www.preprod.modo.com.ar"
  },

  seo:{
    title:"Spot To Go!",
    description:"Descubrí nuestra variedad de productos y promociones."
  },

};

