import { Deserializable } from "./deserializable.model";
import { StoreRange } from './store-range.model';
import { StoreHour } from './store-hour.model';

export class Store implements Deserializable {
	id: number;
	name: string;
	code: string;
	image: string;
	description: string;
	address: string;
	phone: string;
	whatsapp: string;
	image_url: string;
	image_url_xs: string;
	google_map_link: string;
	store_deliver_type_ranges: StoreRange[] = [];
	store_hours: StoreHour[] = [];
	accepts_in_store:boolean;
	accepts_delivery:boolean;
	accepts_to_go:boolean;
	accepts_cash_payments:boolean;
	minimum_amount:number;
	is_opened:boolean;
	has_smartbox:boolean = false;
	has_tables:boolean = false;
	can_order_in_advance:boolean = false;
	deliver_methods:any;
	payments:any = {};
	distanceCurrenPositionForStore:any ;
	lat_x:any;
	lat_y:any;
	nearest:boolean = false;
	accepts_immediate_delivery:boolean = false;
	status_id:any;

	getEnabledDeliverMethods():string[]{
		var aux = [];
		var deliver_types = [];

		if (this.canDeliverBeacon() && this.enabledForMethod('beacon')) {
			aux.push({ deliver_type: ['in_store'], deliver_method: 'beacon'});
		}

		if (this.canDeliverForTable() && this.enabledForMethod('table')) {
			aux.push({ deliver_type: ['in_store', 'to_go'], deliver_method: 'for_table'});
		}

		if (this.canDeliverSmartBox() && this.enabledForMethod('box')) {
			aux.push({ deliver_type: ['to_go'], deliver_method: 'box'});
		}

		if (this.canDeliverCounter()) {
			deliver_types = [];
			if (this.availableForToGo() && this.enabledForMethod('take_away')) {
				deliver_types.push('to_go');
			}

			if (this.availableForStore() && this.enabledForMethod('counter') ) {
				deliver_types.push('in_store');
			}
			
			if(deliver_types.length > 0){
				aux.push({ deliver_type: deliver_types, deliver_method: 'counter'});
			}
		}

		if (this.availableForDelivery() && this.enabledForMethod('delivery')) {
			aux.push({ deliver_type: ['delivery'], deliver_method: 'counter'});
		}

		return aux;
	}

	hasDeliverTimes(type:string):boolean{
		return this.store_deliver_type_ranges && this.store_deliver_type_ranges.filter(sr => sr.deliver_type == type).length > 0;
	}

	isOpened(){
		return this.opened();
	}

	canOrder(){
		return this.isOpened() || this.can_order_in_advance;
	}

	enabledForMethod(method){
		return this.deliver_methods.findIndex((dv) => dv.code == method) >= 0;
	}

	canDeliverForTable():boolean{
		return (this.isOpened() || this.can_order_in_advance) && this.hasTables();
	}

	canDeliverSmartBox():boolean{
		return this.isOpened() && this.hasSmartBox();
	}

	canDeliverCounter():boolean{
		return this.isOpened() || this.can_order_in_advance;
	}

	canDeliverBeacon():boolean{
		return this.isOpened();
	}

	availableForStore():boolean{
		return this.accepts_in_store;
	}

	availableForDelivery():boolean{
		return this.accepts_delivery;
	}

	availableForToGo():boolean{
		return this.accepts_to_go;
	}

	hasSmartBox():boolean{
		return this.has_smartbox;
	}

	hasTables():boolean{
		return this.has_tables;
	}

	deserialize(input: any) {

	    Object.assign(this, input);

	    if (input.store_deliver_type_ranges != undefined) {
	    	this.store_deliver_type_ranges = input.store_deliver_type_ranges.map( (sr) => new StoreRange().deserialize(sr));	
	    }else{
	    	this.store_deliver_type_ranges = [];
	    }

	    if (input.store_hours != undefined) {
	    	this.store_hours = input.store_hours.map( (sh) => new StoreHour().deserialize(sh));	
	    }else{
	    	this.store_hours = [];
	    }

	    this.is_opened = this.opened();
	    
	    return this;
	}

	opened():boolean{
		if(this.status_id == 'close') return false;
		var today = new Date();
		var day_i = today.getDay();
		var sh = this.store_hours.filter( function(x){
			if (x.day_i != today.getDay()) {
				return false;
			}

			var from = new Date(x.from_time);
			var aux_from = new Date(today.getFullYear(), today.getMonth(), today.getDate(), from.getHours(), from.getMinutes(), 0);

			if (today < aux_from) {
				return false;
			}

			var to = new Date(x.to_time);
			var aux_to = new Date(today.getFullYear(), today.getMonth(), today.getDate(), to.getHours(), to.getMinutes(), 0);
			
			if (today > aux_to) {
				return false;
			}

			return true;
		});

		return sh.length>0;
	}

	getOpenTodayHours():StoreHour[]{
		var today = new Date();

		var hours = this.store_hours.filter( (x) => x.day_i == today.getDay() );
		hours.sort( (x, y) => ((new Date(x.from_time)) < (new Date(y.from_time)) ? -1 : 1));

		return hours;
	}

	gotoHelp(text:string = ""): void{
	    var link = "tel://" + this.phone;

	    if (this.whatsapp != "") {
			var link = "https://wa.me/" + this.whatsapp;

			if (text.length > 0) {
				link = link + "?text=" + text
			}
	    }

	    window.open(link, "_blank");
	}

	hasHelp():boolean{
		return this.whatsapp != "" || this.phone != "";
	}

	paymentDefault() {
		let aux = []
		for (const prop in this.payments)
			this.payments[prop] ? aux.push(prop) : null;

		if ( aux.length == 1 )
			return aux[0];
			
		return null
	}

	cardPaymentDefault () {
		let aux = []
		for (const prop in this.payments)
			(prop != 'cash' &&  this.payments[prop]) ? aux.push(prop) : null

		if ( aux.length == 1 )
			return aux[0]

		return null
	}

	setCurrentDistanceToStore( current_longitude:any, current_latitude:any, store_latitud:any, store_longitude:any ) {
		let rad = function(x) {return x*Math.PI/180;}
		
		//Radio de la tierra en km 
		var R = 6378.137;
		var dLat = rad( current_latitude - store_latitud );
		var dLong = rad( current_longitude - store_longitude );
		var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(store_latitud)) * 
		Math.cos(rad(current_latitude)) * Math.sin(dLong/2) * Math.sin(dLong/2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

		//aquí obtienes la distncia aen metros por la conversion 1Km =1000m
		var d = R * c;

		this.distanceCurrenPositionForStore = d;
	}
}
