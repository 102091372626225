import { Deserializable } from "./deserializable.model";

import { Product } from "./product.model";

export class Aditional implements Deserializable{
	default_item:boolean = false;
	id:number;
	name:string = "";
	position:number = 0;
	quantity:number = 0;
	price: number;
	product:Product;
	sub_aditional_categories:any;
	aditional_category_id:any;
	recipe_id:number;

	deserialize(input: any) {
	    Object.assign(this, input);

	    if (input.product != undefined) {
	    	this.product = new Product().deserialize(input.product);	
	    }

	    return this;
	}
}
